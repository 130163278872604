@import "src/styles/_base.module.scss";
.createTeam {
  background: absolute;
  margin-top: 115px;
  border: 2px solid #b7b7b7;
  .topHeading {
    padding: 1.5rem;
    background-color: #ffffff;
    align-items: center;
    // border: 2px solid #b7b7b7;
    border-bottom: 2px solid #b7b7b7;
    border-top: 2px solid #b7b7b7;
    .new {
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      background-color: #f2f2f2;
      padding: 10px;
      margin-right: 10px;
    }
  }
  .teamDetails {
    padding: 20px;
    background-color: #f2f2f2;
    border-bottom: 2px solid #b7b7b7;
    .search {
      // width: 98%;
      background: white;
      border: 1px solid lightgrey;
      display: flex;
      flex-direction: row;
      margin: auto auto auto auto;
      align-items: center;

      label {
        padding: 30px;
        width: 20%;
        font-weight: bold;
      }
      input {
        padding: 10px;
        width: 70%;
        height: min-content;
      }
      select {
        padding: 10px;
        width: 70%;
        height: min-content;
      }
    }
  }
  .rolesSection {
    background-color: #ffffff;
    padding: 1.5rem;
    box-shadow: 1px 1px 4px lightgrey;
    border-bottom: 2px solid #b7b7b7;

    .rolesHeadimg {
      display: flex;
      flex-direction: column;
      margin: 20px 0;
    }
  }
  .first {
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.5rem;
    background-color: #fff;
    box-shadow: 1px 1px 4px lightgrey;
    h5 {
      font-weight: 600;
      font-size: 14px;
      color: #2c3346;
      margin: 0;
      text-transform: capitalize;
    }
    @include to(s) {
      padding: 20px 10px;

      h5 {
        font-size: 14px;
      }
    }
  }
  .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #ffffff;
    color: #32a3fd;
    align-items: center;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
    padding: 1.8rem;
    border-radius: 3px;
  }
  .btnWidth {
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .btnColor {
    background-color: #32a3fd;
    color: white;
  }
  .third {
    background: white;
    .addProjects{
      border: 2px solid #b7b7b7;
      border-bottom: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    h4 {
      padding: 20px 0;
      align-items: center;
      width: 97%;
      margin: auto;
    }

    .second {
      width: 100%;
      margin: auto;
      display: flex;
      flex-direction: row;
      border: 2px solid #dce0e3;
      align-items: center;
      background-color: #f6f8fa;
      justify-content: space-between;
      padding: 1rem;
      border-bottom: none;
      .addRecruiter {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 75%;

        .img {
          border-radius: 50%;
          -moz-border-radius: 50%;
          -webkit-border-radius: 50%;
          background-color: #ffffff;
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
        .imgSquare {

          background-color: #ffffff;
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
        .forth {
          margin: auto 0;
          align-items: center;
          p {
            margin: 0;
          }
          h5 {
            margin: 0;
          }
          @include to(s) {
            h5 {
              font-size: 13px;
            }
            p {
              font-size: 13px;
            }
          }
        }
      }
      .switch {
        .slider {
          span {
            color: #ffffff;
            font-size: 12px;
            font-weight: bold;
          }
          .yes {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 11px;
          }
          .no {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 38px;
          }
        }
      }
    }
    .BorderNone {
      border-bottom: 2px solid #dce0e3;
    }
  }
}
