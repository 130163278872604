@import "src/styles/_base.module.scss";
.wizardPageWrapper {
  text-align: center;
  .wizardWrapper {
    display: inline-block;
    width: 100%;
    margin: rem(170) 0 rem(120) 0;
    @include to(m) {
      margin: rem(160) 0 rem(30) 0;
    }
  }
  .iconContainer {
    width: rem(50);
    height: rem(50);
    background: color('light blue');
    border-radius: 50%;
    margin-bottom: rem(20);
    display: inline-block;
    padding: rem(10);
    svg {
      color: color('blue');
    }
  }
  .wizardTitle {
    text-align: center;
    margin-bottom: rem(40);
    @include to(m) {
      padding: 0 rem(20);
    }
  }
  .wizardContainer {
    background: #fff;
    border: rem(1) solid color('light gray');
    max-width: rem(1200);
    margin: 0 auto;
  }
  .wizardStep-1 {
    max-width: rem(1200);
  }
  .wizardStep-2 {
    max-width: rem(366);
    :global {
      .form-section {
        position: relative;
        .base-card {
          margin: rem(0);
          width: auto;
          float: none;
          text-align: left;
        }
      }
      .section-title {
        box-shadow: none;
      }
    }
  }
  .wizardStep-3 {
    max-width: rem(366);
    :global {
      .form-section {
        position: relative;
        .base-card {
          margin: rem(0);
          width: auto;
          float: none;
          text-align: left;
        }
      }
      .section-title {
        box-shadow: none;
      }
    }
  }
  .skipStep {
    text-align: center;
    margin: rem(50) 0 rem(50) 0;
    color: color('light gray');
    p {
      font-size: rem(14);
    }
  }
}
