@import "src/styles/_base.module.scss";
.searchWrapper {
  padding: rem(20);
  display: block;
  max-width: rem(900);
  .filtersHeading {
    text-align: center;
    margin-top: rem(40);
    margin-bottom: rem(20);
  }
  :global {
    .form-inner-wrapper {
      background: transparent;
    }
  }
  .searchBox {
    @include cf;
    background: none;
    .header {
      // padding: rem(40 0 20);
      svg {
        color: color('light blue');
      }
      h1 {
        margin-top: rem(10);
      }
    }
    > div {
      text-align: center;
    }
    :global {
      .form-elements-wrapper {
        margin: 0 !important;
      }
    }
  }
  .searchBoxWrapper {
    max-width: rem(960);
    margin: 0 auto;
    :global {
      .form-elements {
        width: 100%;
      }
      .form-column:nth-child(1) {
        // @include col(2/5);
      }
      .form-column:nth-child(2) {
        // @include col(2/5);
      }
    }
  }
}

.filtersTriggerHorizontal {
  // padding: rem(10);
  padding: rem(100) rem(21) rem(0) rem(21);
  .filtersTrigger {
    // float: left;
    width: rem(50);
    height: rem(50);
    padding: rem(15);
    svg {
      transition: all ease 0.3s;
      margin: 0;
      display: block;
      width: rem(20);
      height: rem(20);
    }
  }
  &.isOpen {
    padding: rem(21);
    padding-left: rem(10);
    padding-top: rem(74);
    .filtersTrigger {
      svg {
        transform: rotate(90deg);
      }
    }
  }

  h4 {
    font-size: rem(16);
    margin-left: rem(50);
    line-height: rem(40);
  }
}

.searchFilters {
  max-width: rem(1280);
  margin: 0 auto;
  border-radius: rem(3);
  border: rem(1) solid color('light gray');
  box-shadow: 0 rem(1) rem(2) rgba(color('gray'), 0.2);
  background: #fff;
  display: block;
  &.showFilters {
    display: block;
  }
  .sectionContent {
    padding: rem(20);
    .jobrow {
      margin-bottom: rem(20);
    }
    .education {
      //   @include cf;
      margin-bottom: rem(20);
      .qualificationsInput {
        // @include col(2/3);
      }
      .institutionInput {
        // @include col(1/3);
      }
    }
  }
  .selectJobTitles {
    margin-bottom: rem(10);
  }
  .experienceInput,
  .qualificationsInput {
    :global {
      .form-elements-wrapper {
        @include cf;
        .form-element {
          //   @include col(1/5);
        }
      }
    }
  }
  &.asideFilters {
    border-radius: 0;
    border: none;
    box-shadow: none;
    .sectionContent {
      .jobrow,
      .education {
        margin-bottom: rem(0);
      }
    }
    :global {
      .form-column {
        // @include col(1);
        margin-bottom: rem(10);
      }
      .form-elements-wrapper {
        // @include cf;
        .form-element {
          //   @include col(1);
          margin-bottom: rem(10);
        }
      }
      .form-elements {
        width: 100% !important;
      }
    }
  }
}

.messageInput {
  :global {
    .form-elements-wrapper {
      margin-top: rem(20);
    }
    .badge {
      margin-top: rem(-10);
    }
    label.form-element {
      float: left;
      width: auto !important;
    }
  }
}

.updateFiltersContainer {
  height: 78px;
  position: relative;
}
.updateFilters {
  background: #fff;
  padding: rem(20 40);
  width: rem(349);
  position: fixed;
  z-index: +4;
  box-shadow: 0 1px 2px rgba(#000, 0.15);
  @include to(m) {
    width: rem(275);
  }
}
.searchTable {
  padding: 50px;
  @include to(m) {
    padding: 20px;
  }
}

.mailnFilters {
  width: 100%;
  max-width: unset;
}
