@import "src/styles/_base.module.scss";
.profile {
  padding: rem(20);
  // width: rem(350);
  text-align: center;
  box-shadow: 0 rem(1) rem(2) rgba(color('gray'), 0.3);
  position: relative;
  z-index: +1;
}

.profileActions {
  position: relative;
  margin: rem(10) auto 0;
  :global {
    .card-menu {
      position: static;
      display: inline-block;
      vertical-align: top;
      margin: rem(5);
      svg {
        color: color('gray');
        width: rem(23);
        height: rem(23);
      }
    }
    .dropdown {
      display: inline-block;
      width: auto;
      margin: 0;
    }
  }
  .profileCaret {
    position: absolute;
    top: rem(10);
    right: rem(-5);
    color: color('gray');
  }
}

.firstHeading {
  small {
    font-size: 60%;
    margin-left: rem(5);
    color: color('dark gray');
  }
}

.secondHeading {
  color: color('gray');
  font-size: rem(14);
  margin: rem(5 0);
}

.thirdHeading {
  color: color('light gray');
  font-size: rem(12);
  margin: 0;
}

.avatar {
  margin-bottom: rem(20);
}

.badgesWrapper {
  :global {
    .badge {
      display: inline-block;
      margin: rem(5);
    }
  }
}
