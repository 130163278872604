@import "src/styles/_base.module.scss";
.buttonWrapper {
  width: rem(50);
  height: rem(50);
  border-radius: 50%;
  display: block;
  transition: all 0.2s ease;
  cursor: pointer;
  font-weight: bold;
  svg {
    margin: rem(7);
    width: rem(36);
    height: rem(36);
  }
  &.xsmall {
    width: rem(20);
    height: rem(20);
    svg {
      margin: rem(2.5);
      width: rem(15);
      height: rem(15);
    }
  }
  &.small {
    width: rem(30);
    height: rem(30);
    svg {
      margin: rem(6);
      width: rem(18);
      height: rem(18);
    }
  }
  &.medium {
    width: rem(45);
    height: rem(45);
    svg {
      margin: rem(10);
      width: rem(25);
      height: rem(25);
    }
  }
  &.disabled {
    opacity: 0.5;
  }
  &.default {
    background: rgba(color('light gray'), 0.4);
    color: color('dark gray');
    &:not(.disabled):hover {
      background: rgba(color('light gray'), 0.6);
      color: color('dark gray');
      transform: scale(1.1);
    }
    &:not(.disabled) a:hover svg {
      color: color('dark gray');
    }
  }
  &.blue {
    box-shadow: 0 rem(1) rem(2) rgba(color('gray'), 0.4);
    background: color('blue');
    color: #fff;
    &:not(.disabled):hover {
      background: lighten(color('blue'), 5%);
      transform: scale(1.1);
    }
    &:not(.disabled) a:hover svg {
      color: #fff;
    }
  }
}
