@import "src/styles/_base.module.scss";
.dashboardClientsList {
  border-bottom: 1px solid rgba(color('gray'), 0.2);
}

.dashboardSectionList {
  :global {
    .client-profile {
      border-radius: rem(3);
      border: rem(1) solid color('light gray');
      box-shadow: 0 rem(1) rem(2) rgba(color('gray'), 0.2);
      float: left;
      margin-right: rem(20);
      margin-bottom: rem(20);
    }
  }
}

.dashboardWrappper {
  :global {
    .page-section {
      width: 100%;
      overflow: auto;
    }
  }
}

.pageSectionContainer {
  display: flex;
  @include cf;
  > div:last-child {
    margin-right: 0;
  }
}

.clientProjects {
  padding: rem(10 15);
  @include cf;
  .clientProjectsItem {
    float: left;
    margin-right: rem(10);
    &:last-child {
      margin-right: 0;
    }
  }
}

.separator {
  border: none;
  border-top: rem(1) solid #b7bfc4;
  max-width: rem(1280);
  margin: 0 auto;
}
